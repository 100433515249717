export const METRICS_LOADING = "METRICS_LOADING";
export const METRICS_SUCCESS = "METRICS_SUCCESS";

export type Metrics = {
  id: string;
  MCUVersion: string;
  obdTimestamp: string;
  lastAUXVoltage: number;
  lastOEMVoltage: number | string | null;
  lastAUXAmps: number;
  totalEngineRuntime: number;
  milesDriven: number | string | null;
  odometer: number | string | null;
  fuelLevel: number | string | null;
  lastDate: number | string | null;
  lastMPH: number | string | null;
  COTwoProduced: number;
  [key: string]: any;
  tirePressure: number | string | null;
  oilChange: number | string | null;
  crashDetected: number | string | null;
  doorOpenDriving: number | string | null;
  vehicleAlarmTriggered: number | string | null;
  doorOpenRaining: number | string | null;
  seatbeltInUse: number | string | null;
};

export interface MetricsLoading {
  type: typeof METRICS_LOADING;
}

export interface MetricsSuccess {
  type: typeof METRICS_SUCCESS;
  payload: Array<Metrics>;
}

export type MetricsDispatchTypes = MetricsLoading | MetricsSuccess;
