import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers";
import { ReactElement, ReactNode } from "react";
export const VEHICLES_LOADING = "VEHICLES_LOADING";
export const VEHICLES_FAILURE = "VEHICLES_FAILURE";
export const VEHICLES_SUCCESS = "VEHICLES_SUCCESS";

export type VehicleType = {
  vehicles: Array<Vehicles>;
};

export type Vehicles = {
  id: string;
  obdTimestamp: string;
  values: Array<VehicleData>;
  MCUFirmwareVersion: string;
  AssetNumber: string;
};

export type VehicleData = {
  VIN: string;
  Date: string;
  DeviceName: string;
  VehicleIgnitionSignalVolts: number;
  StealthBatteryVolts: number;
  EngineCoolantTemperature: number;
  SystemTemperature: number;
  EngineRunning: boolean;
  InPark: boolean;
  OBDValid: boolean;
  AlternatorAmps: number;
  OEMBatteryVoltage: number;
  SolarAmps: number;
  StealthBatteryAmps: number;
  FuelLevel: number;
  Speed: number;
  RPM: number;
  EngineHours: number;
  CurrentDTCs: string;
  RSSI: number;
  VanairBatteryVoltage: number;
  VanairBatteryAmps: number;
  VanairPackLoadAmps: number;
  ShorelineVolts: number;
  Odometer: number;
  // Comment below out if no Map (Popup)
  AirCompressorOn?: boolean;
  MIL: boolean;
  // Possible Knapheide values
  TirePressure: number;
  SeatbeltInUse: boolean;
  OilChange: boolean;
  CrashDetected: boolean;
  DoorOpenDriving: boolean;
  VehicleAlarmTriggered: boolean;
  DoorOpenRaining: boolean;
};

export interface VehicleLoading {
  type: typeof VEHICLES_LOADING;
}

export interface VehicleFailure {
  type: typeof VEHICLES_FAILURE;
}

export interface VehicleSuccess {
  type: typeof VEHICLES_SUCCESS;
  payload: Array<Vehicles>;
}

export type VehicleDispatchTypes =
  | VehicleLoading
  | VehicleFailure
  | VehicleSuccess;